<template>
  <v-theme-provider light>
    <v-container class="sample-page pa-0" :class="{ overlay: affected }">
      <v-toolbar
          class="px-6"
          flat
          :height="150"
          text
      >
        <v-img class="basic-logo" src="basic_logo.png"></v-img>
        <v-spacer></v-spacer>

        <v-text-field
            class="search-input mt-7 mr-4"
            label="Search"
            solo
            flat
            dense
        >
          <template v-slot:append>
            <v-icon color="white">{{ mdiMagnify }}</v-icon>
          </template>
        </v-text-field>

        <div class="affected-element" id="login">
          <button class="ra21-button" type="button" id="idpbutton" @click="loginTrigger">
            <div class="ra21-button">
              <div class="identityprovider ra21-button-logo-wrap">
                <img class="ra21-button-logo" aria-hidden="true" alt="" src="sa-white.svg">
              </div>
              <div class="identityprovider d-flex justify-content-center align-items-center text-truncate ra21-button-text">
                <div class="text-truncate">
                  <div class="ra21-button-text-primary text-truncate" id="headline"></div>
                  <div class="ra21-button-text-primary text-truncate" id="title">Access through your institution</div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </v-toolbar>
      <v-col>
        <v-row class="px-3">
          <v-col
              class="mt-4"
              cols="3"
          >
            <v-list dense>
              <v-list-item
                  class="px-8 py-0 mt-2"
              >
                <v-list-item-content>
                  <v-list-item-title class="mb-2">KnowledgeWIKI</v-list-item-title>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">Wiki sign</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">Box</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">DNS</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">LNS</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">Drive</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">Play</a>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="inner-content">
                      <a href="#">TCS</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
              class="mt-4"
              cols="6"
          >
            <v-row>
              <v-col>
                <v-banner
                    class="pl-0 pr-4 py-2"
                >
                  <h3>KnowledgeWIKI Support</h3>
                  <br>
                  <p>On the following pages, you will find information on the different levels of integration available to service providers.</p>
                </v-banner>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card
                    class="mx-auto"
                    max-width="344"
                    outlined
                    flat
                >

                  <v-card-title>Card Title</v-card-title>

                  <v-card-text class="pt-4 px-2 pb-0">
                    <p class="text--black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                    class="mx-auto"
                    max-width="344"
                    outlined
                    flat
                >

                  <v-card-title>Card Title</v-card-title>

                  <v-card-text class="pt-4 px-2 pb-0">
                    <p class="text--black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              class="mt-4"
              cols="3"
          >
            <p class="mb-0 mt-3">Important Links</p>
            <a href="#">https://knowledgewiki.policy</a>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </v-theme-provider>
</template>

<script>
import { mdiMagnify } from '@mdi/js';

export default {
  name: 'BasicLink',
  props: {
    affected: {
      type: Boolean,
      default: false
    },
    url: {
      type: String
    }
  },
  data: () => ({
    mdiMagnify: mdiMagnify
  }),
  computed: {
    logout() {
      if (this.$route.query.login) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    loginTrigger() {
      const encodedUrl = encodeURIComponent(`${this.url}/basic-button?login=yes`)
      window.location.href = `${this.url}/Shibboleth.sso/DS/seamless-access?target=${encodedUrl}`
    }
  }
}

</script>

<style lang="scss">

:root {
  --white: #ffffff;
  --text-grey: #444444;
  --warm-grey: #767676;
  --footer-grey: #ebebeb;
  --pastel-blue: #216e93;
  --primary-blue: #0079ff;
  --secondary-blue: #0056b3;
  --dark-blue: #0c1c27;
  --white-50: rgba(255, 255, 255, 0.5);
  --white-two: #f6f6f6;
  --white-three: #e6e6e6;
  --warm-grey-two: #bfbfbf;
}

.sample-page {
  font-family: "Courier", sans-serif;
  background: white;
  color: black;

  #login {
    min-width: 300px;

    .d-flex {
      display: -ms-flexbox!important;
      display: flex!important;
    }

    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .align-items-center {
      align-items: center;
    }

    .justify-content-center {
      justify-content: center;
    }

    button {
      &.ra21-button, &.ra21-button:active {
        width: calc(100% - 10px);
        padding: 0;
        background-color: var(--pastel-blue);
        color: var(--white);
        border-radius: 3px;
        border: none;
        box-shadow: 0 0 0 5px var(--pastel-blue);
      }

      &.ra21-button:hover, &.ra21-button:focus, &.ra21-button:active {
        outline: none;
        box-shadow: 0 0 0 1px, 0 0 0 4px var(--pastel-blue);
      }

      .ra21-button  {
        display: flex;
        padding: 4px;
      }
      .ra21-button-logo-wrap {
        text-align: center;
        width: 50px;
        height: 100%;
        border-right: 1px solid var(--white);
        padding: 5px 5px 5px 0;
      }

      .ra21-button-logo {
        width: 30px;
        vertical-align: middle;
      }

      .ra21-button-text {
        padding-left: 10px;
        text-align: center;
        width: 85%;
        color: inherit;
      }

      .ra21-button-text-primary, .ra21-button-text-secondary, .ra21-access-text {
        font-family: 'Libre Franklin', Arial, sans-serif;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
      }

      .ra21-button-text-primary {
        font-size: 14px;
        font-weight: 700;
      }

      .ra21-button-text-secondary {
        /* Zep */
        font-size: 13px;
        font-weight: normal;
      }
    }

    a {
      &.ra21-access-text {
        width: 100%;
        /* Zep */
        font-size: 13px;
        line-height: 1.46;
        color: var(--pastel-blue);
      }

      &.ra21-access-text:focus {
        outline: none;
        text-decoration: underline;
      }

      &.ra21-access-text:hover {
        color: var(--primary-blue);
        text-decoration: underline;
      }
    }
  }

  h3 {
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: normal !important;
  }

  p {
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: normal !important;
  }

  a {
    color: black !important;
    font-size: 12px !important;
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
  }

  header {
    border-bottom: 2px solid rgba(112,112,112,1) !important;

    button {
      font-size: 14px !important;
    }
  }

  .v-banner {
    max-width: 75%;
    margin: 0 auto;
    color: white !important;
    background: rgba(112,112,112,1) !important;
  }

  .v-card__title {
    background: #c3c3c3 !important;
    text-transform: uppercase !important;
    font-size: 14px !important;
    padding: 0 8px !important;
    color: black;
  }

  .v-sheet.v-card {
    border-radius: 0 !important;
    border-color: #c3c3c3 !important;
  }

  .basic-logo {
    min-width: 85px !important;
    max-width: 85px !important;
    width: 85px !important;
  }

  .search-input {
    flex: 0 0 auto !important;
    font-size: 14px !important;
  }

  .search-input .v-input__slot{
    background: rgba(112,112,112,1) !important;
    color:white;
    width: 200px;
    font-size: 14px !important;
    max-width: 200px;
    min-width: 200px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    label, input {
      color: white !important;
      font-size: 14px !important;
    }
  }

  .v-list-item__title {
    font-size: 14px !important;
  }

  .v-list-item__content {
    padding: 0 0 7px 0 !important;

    a {
      color: black !important;
    }
  }
}
</style>
